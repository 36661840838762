import { formatTime } from "../_helpers/dt";
import { getImageURL, getProfilePhoto } from "../_helpers/files";
import styles from "./styles/ProfileCardSquare.module.css";
import React, { useEffect, useMemo, useRef } from "react";
import { List, Icon } from "semantic-ui-react";

function ProfileCardSquare({ data, number }) {
    const height = useMemo(() => {
        if (data.height > 0) {
            const inches = data.height / 2.54;
            const feet = Math.floor(inches / 12);
            const remainingInches = Math.round(inches % 12);
            return {
                feet: feet,
                inch: remainingInches,
            };
        }
        return null;
    }, [data.height]);

    const mobiles = useMemo(() => {
        const addrs = data.addresses;
        const all = addrs
            .filter((item) => !!item.mobile)
            .map((item) => item.mobile);
        return all || [];
    }, [data?.addresses]);

    return (
        <div className={styles.wrapper}>
            <div className={styles.top_part}>
                <div>
                    <img
                        className={styles.photo}
                        // src={getImageURL(data.main_photo)}
                        src={getProfilePhoto(data.main_photo, data.gender)}
                        alt="main"
                    />
                    <table className={styles.highlight_table}>
                        {data.education?.name && (
                            <tr>
                                <td>
                                    <List.Icon
                                        name="graduation cap"
                                        color="blue"
                                    />
                                </td>
                                <td className={styles.max_lines_1}>
                                    {shortenEducation(data.education?.name)}
                                </td>
                            </tr>
                        )}

                        {height && (
                            <tr>
                                <td>
                                    <Icon
                                        name="arrows alternate vertical"
                                        color="grey"
                                    />
                                </td>
                                <td>{`${height.feet} feet ${height.inch} inch`}</td>
                            </tr>
                        )}
                        {mobiles.length > 0 &&
                            mobiles.map((mob, index) => {
                                if (index > 1) {
                                    return <></>;
                                }
                                return (
                                    <tr>
                                        <td>
                                            <List.Icon
                                                name="phone"
                                                color="green"
                                            />
                                        </td>
                                        <td>{mob}</td>
                                    </tr>
                                );
                            })}
                        {data.maternal_surname && (
                            <tr>
                                <td>
                                    <Icon name="user" color="yellow" />
                                </td>
                                <td className={styles.max_lines_1}>
                                    {data.maternal_surname}
                                </td>
                            </tr>
                        )}
                    </table>
                </div>
                <div className={styles.section}>
                    <h3 className={styles.name}>
                        {toStartCase(data.last_name)}{" "}
                        {toStartCase(data.first_name)}
                        {data.middle_name &&
                            ` ${toStartCase(data.middle_name)}`}
                    </h3>
                    <table className={styles.highlight_table}>
                        {data.location?.name && (
                            <tr>
                                <td>
                                    <List.Icon
                                        name="location arrow"
                                        color="blue"
                                    />
                                </td>
                                <td>{data.location?.name}</td>
                                {data.native_place?.name && (
                                    <>
                                        <td>
                                            <List.Icon
                                                name="map pin"
                                                color="blue"
                                            />
                                        </td>
                                        <td>{data.native_place?.name}</td>
                                    </>
                                )}
                            </tr>
                        )}
                        <tr>
                            {data.gotra?.name && (
                                <>
                                    <td>
                                        <List.Icon name="sun" color="yellow" />
                                    </td>
                                    <td>{data.gotra?.name}</td>
                                </>
                            )}
                            {data.blood_group?.name && (
                                <>
                                    <td>
                                        <List.Icon name="tint" color="red" />
                                    </td>
                                    <td>{data?.blood_group?.name}</td>
                                </>
                            )}
                        </tr>
                        <tr>
                            {data.weight && (
                                <>
                                    <td>
                                        <List.Icon name="weight" color="grey" />
                                    </td>
                                    <td>{parseInt(data.weight)} kg</td>
                                </>
                            )}
                            {data.complexion?.name && (
                                <>
                                    <td>
                                        <List.Icon name="user" color="grey" />
                                    </td>
                                    <td>{data?.complexion?.name}</td>
                                </>
                            )}
                        </tr>
                        <tr>
                            {data.brithday && (
                                <>
                                    <td>
                                        <List.Icon
                                            name="calendar outline"
                                            color="red"
                                        />
                                    </td>
                                    <td>{`${data.brithday}, ${formatTime(
                                        data.birth_time
                                    )}`}</td>
                                </>
                            )}
                            {data.birth_place?.name && (
                                <>
                                    <td>
                                        <List.Icon
                                            name="map marker alternate"
                                            color="red"
                                        />
                                    </td>
                                    <td>
                                        {toStartCase(data?.birth_place?.name)}
                                    </td>
                                </>
                            )}
                        </tr>
                    </table>
                    <table className={styles.highlight_table}>
                        {(data.designation || data.organization_name) && (
                            <tr>
                                <td>
                                    <List.Icon name="briefcase" color="grey" />
                                </td>
                                <td className={styles.max_lines_2}>{`${
                                    data.designation
                                }, ${data.organization_name || ""}`}</td>
                            </tr>
                        )}
                        {data.income && (
                            <tr>
                                <td>
                                    <List.Icon
                                        name="money bill alternate outline"
                                        color="green"
                                    />
                                </td>
                                <td>{`${data.income} ${data.income_currency} per annum`}</td>
                            </tr>
                        )}
                        {data.is_handicapped && (
                            <tr>
                                <td>
                                    <List.Icon
                                        name="wheelchair"
                                        color="purple"
                                    />
                                </td>
                                <td className={styles.max_lines_1}>
                                    {data?.mention_disability}
                                </td>
                            </tr>
                        )}

                        {/* {data.email && (
                            <tr>
                                <td>
                                    <Icon name="mail" color="blue" />
                                </td>
                                <td>{data.email}</td>
                            </tr>
                        )} */}
                    </table>
                    <table className={styles.br_table}>
                        <tr>
                            <td>Brothers</td>
                            <td>M: {data.brothers_married || "-"}</td>
                            <td>UM: {data.brothers_unmarried || "-"}</td>
                        </tr>
                        <tr>
                            <td>Sisters</td>
                            <td>M: {data.sisters_married || "-"}</td>
                            <td>UM: {data.sisters_unmarried || "-"}</td>
                        </tr>
                    </table>
                </div>
            </div>

            <table className={styles.highlight_table}>
                {data.addresses?.length > 0 &&
                    data.addresses.map((item, index) => {
                        // if (index === 1) {
                        //     return (
                        //         <tr>
                        //             <td>
                        //                 <List.Icon name="map" color="blue" />
                        //             </td>
                        //             <td>
                        //                 {item.city}, {item.country?.name},{" "}
                        //                 {item.pincode}, Mob:- {item.moble}
                        //                 <br />
                        //             </td>
                        //         </tr>
                        //     );
                        // }
                        if (index > 0) {
                            return <></>;
                        }
                        return (
                            <tr>
                                <td>
                                    <List.Icon name="map" color="blue" />
                                </td>
                                <td>
                                    <span>
                                        {item.name},{" "}
                                        {item.address?.length > 70
                                            ? `${item.address?.slice(0, 70)}...`
                                            : item.address}
                                    </span>
                                    <br />
                                    {item.city}, {item.country?.name},{" "}
                                    {item.pincode}, Mob:- {item.moble},{" "}
                                    {item.mobile_alternative}
                                    <br />
                                </td>
                            </tr>
                        );
                    })}
                {data.partner_expectations?.length > 0 && (
                    <tr>
                        <td>
                            <List.Icon name="heart" color="red" />
                        </td>
                        <td>
                            <PartnerExpectationText
                                text={data.partner_expectations}
                                max_chars={150}
                                // max_chars={
                                //     data.addresses?.length > 1 ? 100 : 150
                                // }
                            />
                        </td>
                        {/* <td className={styles.max_lines_2}>
                            {data.partner_expectations}
                        </td> */}
                    </tr>
                )}
            </table>

            {/* {data.partner_expectations?.length > 0 && (
                <div className={styles.section}>
                    <p
                        style={{ fontWeight: "bold", color: "blue" }}
                        className={styles.section_heading}
                    >
                        Partner Expectations
                    </p>
                    <p className={styles.max_lines_2}>
                        {data.partner_expectations}
                    </p>
                </div>
            )} */}

            {/* <div className={styles.number}>{number}</div> */}
            <div className={styles.number}>{data.serial_number}</div>
        </div>
    );
}

function toStartCase(inputString) {
    if (!inputString) {
        return "-";
    }
    return inputString
        .toLowerCase()
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
}

function shortenEducation(text) {
    const dashIndex = text.indexOf("-");

    if (dashIndex === -1) {
        return text;
    } else {
        return text.slice(0, dashIndex);
    }
}

function PartnerExpectationText({ text, max_chars = 100 }) {
    // const max_chars = 150; //for 3 lines
    // const max_chars = 100; //for 2 lines
    let out = text.length > max_chars ? text.slice(0, max_chars) : text;

    const lastFullStopIndex = out.lastIndexOf(".");
    if (lastFullStopIndex !== -1) {
        // If a full stop is found, remove everything after it
        out = out.slice(0, lastFullStopIndex + 1);
    }

    return <div>{out}</div>;
}

export default ProfileCardSquare;
