import React from "react";
import { useState } from "react";
import { Button, Card, Form, Input } from "semantic-ui-react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import {
    useForgetPassword,
    useSendForgetPasswordLink,
} from "../_actions/mutations/items";

export default function ForgetPassword() {
    const [data, setData] = useState({});
    const [isdisable, setIsDisable] = useState(true);
    const [err, setErr] = useState("");
    const [issend, setIsSend] = useState(false);

    const [searchParams, setSearchParams] = useSearchParams();
    const token = searchParams.get("token");

    const navigate = useNavigate();
    const reset_mutation = useForgetPassword();
    const sendLinkMutation = useSendForgetPasswordLink(() => {
        console.log("Sended");
    });

    const onSubmit = () => {
        // eve.preventDefault();
        if (token) {
            const payload = {
                token: token,
                password: data.password,
            };
            reset_mutation.mutate(payload);

            //console.log(payload)
        } else {
            const payload = {
                email: data.email,
            };
            sendLinkMutation.mutate(payload, {
                onSuccess: () => {
                    setIsSend(true);
                },
            });
            //console.log(payload)
        }
    };

    useEffect(() => {
        if (data.cpassword != data.password) {
            setErr("Password do not match");
            setIsDisable(true);
        } else {
            setErr("");
            setIsDisable(false);
        }
    }, [data]);

    return (
        <Card
            centered
            style={{
                borderTop: "7px solid #E52424",
                padding: "1em",
                maxWidth: "100%",
                marginTop: "2em",
                width: "400px",
            }}
        >
            <Card.Header
                textAlign="center"
                as={"h3"}
                style={{
                    padding: "20px",
                }}
            >
                Forgot Password
            </Card.Header>
            <Card.Content>
                <Form onSubmit={onSubmit}>
                    {!token ? (
                        <>
                            <Form.Field
                                control={Input}
                                required
                                onChange={(e) => {
                                    setData({ ...data, email: e.target.value });
                                }}
                                value={data.password}
                                label="Register Email address"
                            />
                            {issend ? (
                                <p
                                    style={{
                                        color: "green",
                                        marginTop: "1em",
                                    }}
                                >
                                    Please check your email for reset link!
                                </p>
                            ) : (
                                ""
                            )}
                        </>
                    ) : (
                        <>
                            <Form.Field
                                control={Input}
                                required
                                onChange={(e) => {
                                    setData({
                                        ...data,
                                        password: e.target.value,
                                    });
                                }}
                                value={data.password}
                                label="New Password"
                            />
                            <Form.Field
                                control={Input}
                                required
                                label="Confirm Password"
                                onChange={(e) => {
                                    setData({
                                        ...data,
                                        cpassword: e.target.value,
                                    });
                                }}
                                value={data.cpassword}
                            />
                        </>
                    )}
                    <p
                        style={{
                            color: "red",
                        }}
                    >
                        {err}
                    </p>{" "}
                    <Button
                        primary
                        type="submit"
                        loading={
                            reset_mutation.isLoading ||
                            sendLinkMutation.isLoading
                        }
                        disabled={isdisable}
                    >
                        {token ? "Reset" : "Send Link Reset"}
                    </Button>
                </Form>
            </Card.Content>
        </Card>
    );
}
