import React, { useState, Fragment } from "react";
import styles from "./styles/Navbar.module.css";
import {
    Menu,
    Button,
    Dropdown,
    Icon,
    Sidebar,
    Segment,
    Label,
    Modal as SemanticModal,
} from "semantic-ui-react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { BASE_URL } from "../constants";
import { useLogout } from "../_actions/auth";
import { useCurrentRole } from "../_actions/roles";
import BillingButton from "../home/payment_page/BillingButton";
import { useItemsFilter, useMeItemsFilter } from "../_actions/items";
import Modal from "./Modal";
import ModalCmp from "./Modal";

function getRandomNumberBetween(min, max) {
    const randomNumber = Math.random();
    const randomInteger = Math.floor(randomNumber * (max - min + 1)) + min;
    return randomInteger;
}

function Navbar({ user_name = "User", isMobile }) {
    const [open, setOpen] = useState(false);
    const { role_name } = useCurrentRole();
    const { data: book_status } = useMeItemsFilter(
        "",
        "?fields=suchi_book_order"
    );
    const { data: settings } = useItemsFilter("admin_setting", "");

    console.log(book_status);

    const [visible, setVisible] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const {
        logout,
        loading: loadingLogout,
        success: successLogout,
    } = useLogout();

    console.log("Role name", role_name);

    const menu_items_center = (
        <>
            <Link to="/">
                <Menu.Item
                    name="logo"
                    style={{
                        padding: 0,
                    }}
                >
                    <img
                        // src="https://api.qureal.com/passets/16480c10-a1de-4546-9a03-68323ab3b412.png"
                        src="https://matrimonialapi.qureal.com/assets/f187d1a4-8bd5-4ec6-bd8d-4f907c787f7b"
                        alt="LOGO"
                        style={{
                            width: "auto",
                            maxWidth: "15em",
                            height: "4em",
                            objectFit: "contain",
                            marginRight: isMobile ? "20px" : "50px",
                            padding: "7px 0px",
                            marginBottom: isMobile ? "30px" : 0,
                            cursor: "pointer",
                        }}
                    />
                </Menu.Item>
            </Link>
            <Link to="/">
                <Menu.Item name="Home" active={location.pathname === "/"} />
            </Link>
            {role_name === "user" && (
                <Link to="/candidate/view">
                    <Menu.Item
                        name="Candidate Profile"
                        active={location.pathname.startsWith("/candidate")}
                    />
                </Link>
            )}
            <Link to="/settings">
                <Menu.Item
                    name="Settings"
                    active={location.pathname.startsWith("/settings")}
                />
            </Link>

            {role_name === "admin" && (
                <Link to="/admin">
                    <Menu.Item
                        name="Admin"
                        active={location.pathname.startsWith("/admin")}
                    />
                </Link>
            )}

            {/* {!book_status?.suchi_book_order && <BillingButton total={600} />} */}

            {false && settings?.isactive_suchibook && (
                <div
                    style={{
                        paddingTop: isMobile ? "10px" : 0,
                        paddingLeft: isMobile ? "5px" : 0,
                    }}
                >
                    <Label
                        className="p-2"
                        color="green"
                        as="a"
                        onClick={() => {
                            const num = getRandomNumberBetween(1, 100);
                            navigate(`/?buysuchi=${num}`);
                        }}
                    >
                        <Icon name="book" />
                        Buy Suchi Online
                    </Label>
                </div>
            )}

            {false && settings?.isactive_suchibook && (
                <SemanticModal
                    trigger={
                        <Label
                            className="p-2"
                            color="green"
                            as="a"
                            onClick={() => {
                                const num = getRandomNumberBetween(1, 100);
                                navigate(`/?buysuchi=${num}`);
                            }}
                        >
                            <Icon name="book" />
                            Buy Suchi Online
                        </Label>
                    }
                    header="Suchi Booking on hold"
                    content=" There is a issue with payment gateway. it will be resolved soon.
                    please try again in 2 days."
                    actions={[{ key: "done", content: "Okay", positive: true }]}
                    size="tiny"
                />
            )}
        </>
    );

    return (
        <Fragment>
            {/* Mobile Sidebar */}
            <div
                className={styles.sidebar}
                style={{
                    left: visible ? 0 : "-60%",
                }}
            >
                <Menu
                    vertical
                    secondary
                    fluid
                    onClick={() => setVisible(false)}
                >
                    {menu_items_center}
                    <div
                        style={{
                            borderBottom: "1px solid #eee",
                            margin: "20px 10px",
                        }}
                    ></div>
                    <Link to="/settings/account">
                        <Menu.Item
                            active={location.pathname.startsWith(
                                "/settings/account"
                            )}
                        >
                            Profile
                        </Menu.Item>
                    </Link>
                    <Link to="/fav_members">
                        <Menu.Item
                            active={location.pathname.startsWith(
                                "/fav_members"
                            )}
                        >
                            Favorite Profile
                        </Menu.Item>
                    </Link>
                    <Link to="/save_note_members">
                        <Menu.Item
                            active={location.pathname.startsWith(
                                "/save_note_members"
                            )}
                        >
                            Noted Profile
                        </Menu.Item>
                    </Link>
                    <Link to="/settings/reset_password">
                        <Menu.Item
                            name="Password Reset"
                            active={location.pathname.startsWith(
                                "/settings/reset_password"
                            )}
                        />
                    </Link>
                    <Link>
                        <Menu.Item
                            onClick={() => {
                                logout();
                                // navigate("/login");
                            }}
                            loading={loadingLogout}
                        >
                            Logout
                        </Menu.Item>
                    </Link>
                </Menu>
            </div>
            <div className={styles.wrapper}>
                {!isMobile && (
                    <Menu
                        style={{
                            maxWidth: "1080px",
                            margin: "0 auto",
                            backgroundColor: "transparent",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                        }}
                        // inverted
                        secondary
                    >
                        {menu_items_center}
                        <Menu.Menu position="right">
                            <Menu.Item
                                icon
                                style={{
                                    marginRight: -20,
                                }}
                            >
                                <Icon name="user outline" />
                            </Menu.Item>
                            <Dropdown
                                item
                                text={`${user_name}`}
                                style={{
                                    marginLeft: -20,
                                    paddingLeft: 40,
                                }}
                            >
                                <Dropdown.Menu>
                                    <Dropdown.Item
                                        onClick={() =>
                                            navigate("settings/account")
                                        }
                                    >
                                        Profile
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        text="Password Reset"
                                        onClick={() => {
                                            navigate("settings/reset_password");
                                        }}
                                    />
                                    <Dropdown.Item
                                        onClick={() => {
                                            logout();
                                            // navigate("/login");
                                        }}
                                        loading={loadingLogout}
                                    >
                                        Logout
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Menu.Menu>
                    </Menu>
                )}
                {isMobile && (
                    <div className={styles.wrapper_mobile}>
                        <Link to="/">
                            <img
                                src="https://matrimonialapi.qureal.com/assets/f187d1a4-8bd5-4ec6-bd8d-4f907c787f7b"
                                alt="LOGO"
                                style={{
                                    // width: "12em",
                                    // maxWidth: "15em",
                                    height: "4em",
                                    cursor: "default",
                                    objectFit: "contain",
                                    marginLeft: "15px",
                                    marginRight: "10px",
                                    padding: "7px 0px",
                                }}
                            />
                        </Link>
                        <h3 style={{ margin: 0, padding: 0 }}>
                            Vikas Mandal Mulund
                        </h3>
                        <Menu.Item
                            onClick={() => setVisible(!visible)}
                            style={{
                                cursor: "pointer",
                                color: "#888",
                            }}
                        >
                            {visible ? (
                                <Icon
                                    name="times"
                                    style={{
                                        fontSize: "1.5em",
                                        paddingRight: "45px",
                                    }}
                                />
                            ) : (
                                <Icon
                                    name="bars"
                                    style={{
                                        fontSize: "1.5em",
                                        paddingRight: "45px",
                                    }}
                                />
                            )}
                        </Menu.Item>
                    </div>
                )}
            </div>
        </Fragment>
    );
}

export default Navbar;
