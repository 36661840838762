import React, { useState } from 'react'
import { RAZORPAYKEY } from '../constants'

const NAME = 'Vikas Mandal Mulund'
const DESCRIPTION = 'Search Subscription Payment'
const IMAGE =
  'https://matrimonialapi.qureal.com/assets/f187d1a4-8bd5-4ec6-bd8d-4f907c787f7b'

function PayButton ({
  name,
  email,
  mobile,
  price,
  notes,
  order,
  onSuccess = () => {},
  onFailed = () => {},
  trigger = <button>Pay</button>
}) {
  const [status, setStatus] = useState('idle')

  function loadScript (src) {
    return new Promise(resolve => {
      const script = document.createElement('script')
      script.src = src
      script.onload = () => {
        resolve(true)
      }
      script.onerror = () => {
        resolve(false)
      }
      document.body.appendChild(script)
    })
  }

  const displayRazorpay = async () => {
    if (status === 'pending') {
      return
    }
    console.log('Init payment')
    setStatus('pending')
    const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js')

    if (!res) {
      alert('Razorpay SDK failed to load. Are you online?')
      return
    }

    const order_id = order?.order_id
    const amount = price * 100
    const currency = 'INR'

    const options = {
      key: RAZORPAYKEY,
      amount: amount.toString(),
      currency: currency,
      name: NAME,
      description: DESCRIPTION,
      image: IMAGE,
      order_id: order_id,
      handler: async function (response) {
        console.log('Success Handler response', response)
        setStatus(JSON.stringify(response))
        onSuccess(response)
        setStatus('success')
      },
      prefill: {
        name: `${name}`,
        email: `${email}`,
        contact: `${mobile || ''}`
      },
      notes: {
        ...notes,
        qureal_order_id: order?.id
      },
      theme: {
        color: '#ee3435'
      }
    }
    console.log('rz options', options)

    const rzpy = new window.Razorpay(options)

    rzpy.on('payment.authorized', function (response) {
      console.log('Payment triggered', response)
      onSuccess()
      setStatus('success')
    })

    rzpy.on('payment.captured', function (response) {
      console.log('Payment Captured', response)
      setStatus('success')
    })

    rzpy.on('payment.failed', function (response) {
      console.log('Payment Failed', response)
      onFailed()
      setStatus('failed')
    })

    rzpy.on('order.paid', function (response) {
      console.log('Order paid', response)
      onSuccess()
      setStatus('success')
    })

    rzpy.open()
  }

  return (
    <div>
      <span
        style={{ cursor: 'pointer' }}
        onClick={e => {
          e.stopPropagation()
          //   displayRazorpay()
          alert('Payment is currently disabled.')
        }}
      >
        {trigger}
      </span>
    </div>
  )
}

export default PayButton
