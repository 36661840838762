import React from "react";
import { useItemsFilter } from "../_actions/items";
import OrderCard from "./OrderCard";
import NoCandidate from "../profile/NoCandidate";

function ListOrders({ payment_status = "paid", order_status = "ordered" }) {
    const { isLoading, isSuccess, data } = useItemsFilter(
        "qd_order",
        `?sort=-date_updated&limit=-1&filter[status][_eq]=${payment_status}&filter[order_status][_eq]=${order_status}&fields=id,user.first_name,user.last_name,order_date,order_status,razorpay_order_id,razorpay_payment_id,amount,status,name,address,city,pincode,mobile,alternate_mobile,date_updated,user.matrimonial_profiles.id,user.matrimonial_profiles.main_photo.*,user.matrimonial_profiles.gender`,
        true
    );

    console.log("List orders", data);
    return (
        <div>
            {isSuccess && data?.length === 0 && (
                <NoCandidate text={`No ${order_status} order found`} />
            )}
            {isSuccess && <p>Total {data?.length} orders</p>}
            {isSuccess && (
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                        padding: "10px 0",
                    }}
                >
                    {data.map((item) => (
                        <OrderCard
                            order={item}
                            user={item.user}
                            profile={item.user?.matrimonial_profiles?.[0]}
                        />
                    ))}
                </div>
            )}
        </div>
    );
}

export default ListOrders;
