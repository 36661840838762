import React, { useMemo, useState, useEffect } from "react";
import Screen from "../commons/Screen";
import { Button, Icon } from "semantic-ui-react";
import CandidateForm from "./CandidateForm";
import { useItemsFilter } from "../_actions/items";
import { useCurrentUser, useMe } from "../_actions/auth";
import { useSearchParams } from "react-router-dom";

function CandidateFormScreen({ edit = true }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const get_user = searchParams.get("user");
  //   console.log("GETT_USER", get_user);
  const { isSuccess, isLoading, data } = useItemsFilter(
    "qd_matrimonial_profile",
    `?filter[user][_eq]=${
      get_user ? get_user : "$CURRENT_USER"
    }&fields=*,photos.*,addresses.*`,
    true
  );

  const user = useCurrentUser();
  const [profile_data, setProfileData] = useState({});

  //   console.log("ussserss", user);

  useEffect(() => {
    if (isSuccess && data?.length > 0) {
      const temp = data[0];
      setProfileData(temp);
    }
  }, [isSuccess, data]);

  return (
    <Screen
      title={edit ? "Edit Candidate" : "Add Candidate"}
      wrapper_styles={{
        paddingTop: "20px",
      }}
      // actions={[
      //     <Button primary icon labelPosition="left">
      //         <Icon name="edit" />
      //         Save Changes
      //     </Button>,
      // ]}
    >
      <CandidateForm
        initial_data={profile_data}
        edit={edit}
        user_id={get_user ? get_user : user?.id}
      />
      <div
        style={{
          height: "200px",
        }}
      ></div>
    </Screen>
  );
}

export default CandidateFormScreen;
