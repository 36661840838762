import React, { Fragment, useState } from "react";
import { useItemsFilter, useItemsFilterNew } from "../_actions/items";
import styles from "./styles/AllProfiles.module.css";
import { getProfilePhoto } from "../_helpers/files";
import {
    Button,
    Form,
    Icon,
    Modal,
    Pagination,
    TextArea,
} from "semantic-ui-react";
import { useUpdateItem } from "../_actions/mutations/items";
import { Link } from "react-router-dom";
import NoCandidate from "../profile/NoCandidate";
import { useSendMail } from "../_actions/mail";
import RejectionReasonDropdown from "../admin/RejectionReasonDrodown";
import { getTime } from "../_helpers/dt";
import { useEffect } from "react";

const fields =
    "fields=first_name,last_name,date_updated,gender,main_photo,id,status,email,native_place.*,user_created.*,is_hidden,user.mobile&sort=-date_updated&filter[is_hidden][_eq]=false";

function AllProfiles({
    status,
    show_last_updated = false,
    call_btn = false,
    per_page = 10,
    search_query = "",
}) {
    const [total, setTotal] = useState(1);
    const [num_page, setNumPage] = useState(1);
    const [page_no, setPageNo] = useState(1);
    //==========
    const { isLoading, isSuccess, data } = useItemsFilterNew(
        "qd_matrimonial_profile",
        `?${fields}&${search_query}&meta=filter_count&limit=${per_page}&offset=${
            per_page * (page_no - 1)
        }${status ? `&filter[status][_eq]=${status}` : ``}`,
        // status ? `?${fields}&filter[status][_eq]=${status}` : `?${fields}`,
        true
    );

    useEffect(() => {
        setPageNo(1);
    }, [search_query]);

    useEffect(() => {
        if (isSuccess) {
            const total_records = data?.meta?.filter_count;
            if (total_records > 0) {
                setTotal(total_records);
                setNumPage(Math.ceil(total_records / per_page));
            }
            console.log("User Response", data);
        }
    }, [isSuccess, data]);

    return (
        <div>
            {isSuccess && data?.data?.length <= 0 && (
                <NoCandidate text="No candidate availabe here." />
            )}
            {isLoading && <NoCandidate text="Loading..." />}
            {isSuccess && (
                <>
                    <p
                        style={{
                            color: "green",
                        }}
                    >
                        Total Candidates: {total}
                    </p>
                    <div className={styles.grid_wrapper}>
                        {data?.data?.map((item) => (
                            <div className={styles.item_wrapper}>
                                <div className={styles.item}>
                                    <img
                                        src={getProfilePhoto(
                                            item?.main_photo,
                                            item?.gender
                                        )}
                                        alt="profile"
                                    />
                                    <div>
                                        <h4>
                                            {item?.first_name} {item?.last_name}
                                        </h4>
                                        {/* <p>{item?.native_place?.name}</p> */}
                                        <p>{item?.native_place?.name}</p>
                                        {show_last_updated &&
                                            item.date_updated && (
                                                <p>
                                                    Last updated:{" "}
                                                    {getTime(item.date_updated)}
                                                </p>
                                            )}
                                        {/* <p>DU:{getTime(item.date_updated, "d")}</p> */}
                                    </div>
                                </div>
                                <ProfileActions
                                    item={item}
                                    call_btn={call_btn}
                                />
                            </div>
                        ))}
                    </div>
                </>
            )}
            <Pagination
                activePage={page_no}
                totalPages={num_page}
                onPageChange={(e, { activePage }) => setPageNo(activePage)}
            />
        </div>
    );
}

export function ProfileActions({ item, view = true, call_btn }) {
    const { sendMail, sendMailToAdmin } = useSendMail();
    const [reject, setReject] = useState({
        open: false,
        id: null,
        reason: "",
    });
    const updateMutation = useUpdateItem("qd_matrimonial_profile", null, () => {
        setReject({
            open: false,
            id: null,
            reason: null,
        });
    });
    const [id_in_process, setIdInProcess] = useState(null);

    return (
        <Fragment>
            <div className={styles.action_wrapper}>
                {view && (
                    <Link to={`/admin/candidates/view/${item.id}`}>
                        <Button primary>View</Button>
                    </Link>
                )}
                {/* <Link to={`/admin/candidates/edit/${item.id}`}>
        <Button primary>Edit</Button>
    </Link> */}
                {(item.status === "Pending" || item.status === "Rejected") && (
                    <Button
                        positive
                        onClick={() => {
                            setIdInProcess(item.id);
                            updateMutation.mutate({
                                id: item.id,
                                data: {
                                    status: "Active",
                                },
                            });
                            sendMail(
                                item.email,
                                "Your matrimonial profile is approved",
                                "Your profile is live now",
                                "We are happy to onboard you. Thanks for creating profile on our platform."
                            );
                        }}
                        loading={
                            id_in_process === item.id &&
                            updateMutation.isLoading
                        }
                    >
                        Approve
                    </Button>
                )}
                {item.status === "Pending" && (
                    <Button
                        color="black"
                        onClick={() =>
                            setReject({
                                open: true,
                                id: item.id,
                                email: item.email,
                                reason: "",
                            })
                        }
                    >
                        Reject
                    </Button>
                )}
                {item.status === "Active" && (
                    <Button
                        color="red"
                        onClick={() => {
                            setIdInProcess(item.id);
                            updateMutation.mutate({
                                id: item.id,
                                data: {
                                    status: "Blocked",
                                },
                            });
                            sendMail(
                                item.email,
                                "Your matrimonial profile is blocked",
                                "Your profile is blocked",
                                "For not following the platform guidelines, your profile has been blocked. mail use to unblock"
                            );
                        }}
                        loading={
                            id_in_process === item.id &&
                            updateMutation.isLoading
                        }
                    >
                        Block
                    </Button>
                )}
                {item.status === "Blocked" && (
                    <Button
                        positive
                        onClick={() => {
                            setIdInProcess(item.id);
                            updateMutation.mutate({
                                id: item.id,
                                data: {
                                    status: "Active",
                                },
                            });
                            sendMail(
                                item.email,
                                "Your matrimonial profile is active again",
                                "Your profile is unblocked",
                                "We are happy to inform you that your matrimonial profile has been unblocked and active again."
                            );
                        }}
                        loading={
                            id_in_process === item.id &&
                            updateMutation.isLoading
                        }
                    >
                        Unblock
                    </Button>
                )}
                {call_btn && item.user?.mobile && (
                    <a href={`tel:${item.user?.mobile}`}>
                        {" "}
                        <Button icon labelPosition="left" positive>
                            <Icon name="call" />
                            Call
                        </Button>
                    </a>
                )}
            </div>
            <Modal
                size="tiny"
                open={reject.open}
                onOpen={() => setReject({ ...reject, open: true })}
                onClose={() => setReject({ ...reject, open: false })}
            >
                <Modal.Header>Reason for rejection</Modal.Header>
                <Modal.Content>
                    <Form>
                        <RejectionReasonDropdown
                            value={reject.reason}
                            setValue={(new_val) =>
                                setReject({
                                    ...reject,
                                    reason: new_val,
                                })
                            }
                        />
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        disabled={reject.reason?.length < 5}
                        color="black"
                        onClick={() => {
                            updateMutation.mutate({
                                id: reject.id,
                                data: {
                                    status: "Rejected",
                                    rejection_reason: reject.reason,
                                },
                            });
                            sendMail(
                                reject.email,
                                "Your matrimonial profile is rejected",
                                "Reason for rejection",
                                reject.reason
                            );
                        }}
                        icon
                        labelPosition="right"
                        loading={updateMutation.isLoading}
                    >
                        Reject
                        <Icon name="arrow right" />
                    </Button>
                </Modal.Actions>
            </Modal>
        </Fragment>
    );
}

export default AllProfiles;
