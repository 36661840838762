import React, { useMemo, useState } from 'react'
import MessageBox from '../commons/MessageBox'
import { Button, Checkbox, Form, Icon, Modal } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import Confetti from 'react-confetti'
import { useUpdateItemNew, useUpdateMe } from '../_actions/mutations/items'

function ReactivationBox ({ profile }) {
  const [open, setOpen] = useState(false)
  const [value, setValue] = useState({
    married: false,
    married_by_vmm: false
  })
  const mutation_update_me = useUpdateMe(() => {
    setOpen(false)
  })
  const mutationUpdate = useUpdateItemNew(
    'qd_matrimonial_profile',
    () => {},
    'user'
  )

  const handleChange = (e, { value }) =>
    setValue({ married: value, married_by_vmm: false })

  const proceed = () => {
    if (value.married === 'yes') {
      //mark as married
      mutationUpdate.mutate({
        id: profile?.id,
        data: {
          status: 'married',
          marital_status: 'married',
          last_status: profile?.status,
          married_by_vmm: value.married_by_vmm
        }
      })
    }
    // mark profile as reactivated
    mutation_update_me.mutate({
      is_reactivated: true
    })
  }

  return (
    <MessageBox
      type='info'
      title='Your account is deactivated.'
      content='Website based  online profile search is disabled as we are registration process for the oncoming marriage season 2024-25 has now started. Please register fresh profiles and existing candidates to revalidate and activate their profiles if required.'
      allow_close={false}
      actions={[
        <Modal
          size='small'
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          trigger={
            <Button
              positive
              icon
              labelPosition='right'
              onClick={() => setOpen(true)}
            >
              Reactivate Account
              <Icon name='arrow right' />
            </Button>
          }
        >
          <Modal.Header>Reactivate Profile</Modal.Header>
          <Modal.Content>
            {value.married === 'yes' && (
              <Confetti
                // width={width} height={height}
                width='700px'
              />
            )}
            <Form>
              <Form.Field>
                <label>Kindly revalidate your existing profiles </label>
              </Form.Field>
              <Form.Field>
                <Form.Radio
                  label='I am looking for suitable life partner'
                  value='no'
                  checked={value.married === 'no'}
                  onChange={handleChange}
                />
              </Form.Field>
              <Form.Field>
                <Form.Radio
                  label='I am married'
                  value='yes'
                  checked={value.married === 'yes'}
                  onChange={handleChange}
                />
              </Form.Field>
              {value.married === 'yes' && (
                <Form.Field>
                  <Form.Checkbox
                    onChange={(e, { checked }) =>
                      setValue({ ...value, married_by_vmm: checked })
                    }
                    label='We will be happy to know if it was facilitated through our platform'
                  />
                </Form.Field>
              )}
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button color='black' onClick={() => setOpen(false)}>
              Cancel
            </Button>
            <Button
              positive
              icon='checkmark'
              labelPosition='right'
              content='Proceed'
              disabled={!value.married}
              onClick={proceed}
              loading={mutation_update_me.isLoading}
            />
          </Modal.Actions>
        </Modal>
      ]}
    />
  )
}

export default ReactivationBox
