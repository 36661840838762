export const BASE_URL = "https://matrimonialapi.qureal.com/";

// export const RAZORPAYKEY = "rzp_live_E1eKTorBSlKMKp";
export const RAZORPAYKEY = "rzp_live_eq3eIK3njfJTQV"; //prod
// export const RAZORPAYKEY = "rzp_test_q4sEwjjOo9ZCUg"; //test

// export const SUCHI_PRICE = 2; //test
export const SUCHI_PRICE = 600; //prod

export const SEARCH_SUBSCRIPTION_PRICE = 600;
export const SEARCH_SUBSCRIPTION_PRICE_TESTER = 2;

export const EXTERNAL_URL = "";

export const ROLE_USER = "d664c2b6-6f09-4472-b503-df1897e667e6"; //OrgUser
export const ROLE_ADMIN = "882d9638-26ea-41ca-92f5-04fe4538478b"; //OrgAdmin

export const HIDE_NA = false;

export const ENABLE_MAIL = true;

export const ADMIN_MAILS = "vikasmandalmulund@gmail.com";

// in seconds
export const AUTOSAVE_DURATION = 10;

export const IS_DEV = true;

export const REJECTION_REASONS = [
    "Photo is not clear",
    "Please fill gotra",
    "Partner expectation is not proper",
    "Other",
];

export const TV_SLIDESHOW_DURATION = 3;
