import React from "react";

export default function PaymentCard(props) {
  const handleChange = (e) => {
    if (e.target.checked) {
      if (props.pur == "profile_search") {
        props.setPayload({
          ...props.payload,
          membership_valid_till: "2024-05-31",
        });
      } else if (props.pur == "book_suchi") {
        props.setPayload({
          ...props.payload,
          suchi_book_order: "ordered",
        });
      }
      props.setPrice(props.price + 600);
    } else {
      if (props.pur == "profile_search") {
        delete props.payload["membership_valid_till"];
        props.setPayload({ ...props.payload });
      } else if (props.pur == "book_suchi") {
        delete props.payload["suchi_book_order"];
        props.setPayload({ ...props.payload });
      }
      props.setPrice(props.price - 600);
    }
  };
  return (
    <>
      <div
        style={{
          boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
          padding: "1em",
          marginBottom: "4em",
          width: "28em",
        }}
      >
        {props.mode && (
          <h4
            style={{
              color: "green",
            }}
          >
            Activated
          </h4>
        )}
        <h5
          style={{
            textAlign: "center",
          }}
        >
          {props?.title}
        </h5>

        <h4
          style={{
            textAlign: "center",
          }}
        >
          INR 600/-
        </h4>
        {!props.mode ? (
          <div
            style={{
              display: "flex",
            }}
          >
            <input
              onChange={handleChange}
              style={{
                width: "1em",
                margin: "auto",
              }}
              type="checkbox"
            />
          </div>
        ) : (
          ""
        )}
        <p
          style={{
            fontSize: "14px",
            fontWeight: "600",
            marginTop: "1em",
            textAlign: "center",
          }}
        >
          *{props?.note}
        </p>
        {props.status && (
          <h6
            style={{
              fontSize: "14px",
              fontWeight: "600",
              marginTop: "1em",
              textAlign: "center",
              color: "red",
              textTransform: "capitalize",
            }}
          >
            Order Status: {props.status}
          </h6>
        )}
      </div>
    </>
  );
}
