import React, { useState, useEffect } from "react";
import {
    Button,
    Checkbox,
    Dropdown,
    Form,
    Icon,
    Input,
    Label,
    Modal,
    TextArea,
} from "semantic-ui-react";
import LookupDropdown from "../forms/LookupDropdown";
import RelationDropdown from "../forms/RelationDropdown";
import ImageField from "../forms/ImageField";
import AddressPicker from "./AddressPicker";
import { useCreateItem, useUpdateItem } from "../_actions/mutations/items";
import { useNavigate, useSearchParams } from "react-router-dom";
import HeightField from "../forms/HeightField";
import { countWords } from "../utils";
import { useSendMail } from "../_actions/mail";
import { AUTOSAVE_DURATION } from "../constants";
import VideoPicker from "./VideoPicker";

function CandidateForm({ initial_data = {}, edit, user_id }) {
    const [searchParams, setSearchParams] = useSearchParams();
    const [autosave, setAutosave] = useState(true);
    const [err, setErr] = useState({});
    const [id, setId] = useState(null);
    const { sendMailToAdmin } = useSendMail();
    const navigate = useNavigate();
    const get_user = searchParams.get("user");
    const createMutation = useCreateItem(
        "qd_matrimonial_profile",
        "user",
        () => {},
        (res) => {
            if (!autosave) {
                navigate("/candidate/view");
            } else {
                if (res?.id) {
                    setId(res.id);
                }
            }
        }
    );
    const updateMutation = useUpdateItem("qd_matrimonial_profile", null);
    const [data, setData] = useState({});
    const [dialog, setDialog] = useState({
        open: false,
        msg: "You are marking yourself as handicapped. want to go ahead?",
    });
    const [height, setHeight] = useState({
        feet: 0,
        inch: 0,
    });
    //validations
    const [invalids, setInvalids] = useState({});

    useEffect(() => {
        if (initial_data?.id) {
            setId(initial_data.id);
        }
        if (countWords(initial_data.partner_expectations || "") < 10) {
            setInvalids({ ...invalids, partner_expectations: true });
        } else {
            setInvalids({ ...invalids, partner_expectations: false });
        }
    }, [initial_data]);

    useEffect(() => {
        if (countWords(data.partner_expectations || "") < 10) {
            setInvalids({ ...invalids, partner_expectations: true });
        } else {
            setInvalids({ ...invalids, partner_expectations: false });
        }
    }, [data.partner_expectations]);

    const validations_obj = {
        income: 20000000,
        brothers_unmarried: 10,
        brothers_married: 10,
        sisters_married: 10,
        sisters_unmarried: 10,
        weight: 200,
    };

    const handleChange = (e) => {
        if (Number(e.target.value) > validations_obj[e.target.name]) {
        } else {
            setData({
                ...data,
                [e.target.name]: e.target.value,
            });
        }
    };

    const combined_data = {
        ...initial_data,
        ...data,
    };

    useEffect(() => {
        const inches = combined_data.height / 2.54;
        const feet = Math.floor(inches / 12);
        const remainingInches = Math.round(inches % 12);
        setHeight({
            feet: feet,
            inch: remainingInches,
        });
    }, [combined_data.height]);

    const validateData = (data) => {
        const verr = {};
        if (countWords(data.partner_expectations || "") < 10) {
            verr["partner_expectations"] =
                "Partner expectations must have atleast 10 words";
        }
        if (data?.addresses?.length <= 0) {
            verr["addresses"] = "Add atleast one address";
        }
        if (!data?.first_name) {
            verr["first_name"] = "First Name Required";
        }
        if (!data?.last_name) {
            verr["last_name"] = "Last Name Required";
        }
        if (!data?.gender) {
            verr["gender"] = "Gender Required";
        }
        if (!data?.maternal_surname) {
            verr["maternal_surname"] = "Maternal Surname Required";
        }
        if (!data?.native_place && !data.new_native_place) {
            verr["native_place"] = "Native Place Required";
        }
        if (!data?.main_photo) {
            verr["first_name"] = "Main Photo Required";
        }
        if (!data?.marital_status) {
            verr["marital_status"] = "Marital Status Required";
        }
        if (!data?.birth_place && !data.new_birth_place) {
            verr["bith_place"] = "Birth Place Required";
        }
        if (!data?.birth_time) {
            verr["bith_time"] = "Birth time Required";
        }
        if (!data?.brithday) {
            verr["birthday"] = "Birth Date Required";
        }
        if (!data?.height) {
            verr["height"] = "Height Required";
        }
        if (!data?.weight) {
            verr["weight"] = "Weight Required";
        }
        if (!data?.blood_group) {
            verr["blood_group"] = "Blood Group Required";
        }
        if (!data?.gotra) {
            verr["gotra"] = "Gotra Required";
        }
        if (!data?.job_type) {
            verr["job_type"] = "Job Type Required";
        }
        if (!data?.income) {
            verr["income"] = "Income Type Required";
        }
        if (!data?.location && !data.new_location) {
            verr["location"] = "Work Location Required";
        }
        if (!data?.country && !data.new_country) {
            verr["country"] = "Country Type Required";
        }
        if (!data?.education) {
            verr["eduction"] = "Eduction Type Required";
        }
        return verr;
    };

    const prepareData = () => {
        const validation_errors = validateData(combined_data);

        // ("validationnn", validation_errors);

        // ("Heiht", height);
        const totalInches = height.feet * 12 + height.inch;
        const cm = totalInches * 2.54;

        const out = {
            ...data,
            status:
                Object.keys(validation_errors).length === 0
                    ? "Pending"
                    : "Draft",
            height: cm,
            validation_errors,
        };

        // ("OUT", out);
        return out;
    };

    const submitForm = (status) => {
        setAutosave(false);
        let out = prepareData();
        if (status) {
            out = {
                ...out,
                status: status,
            };
        }
        const is_edit = !!id;
        if (is_edit) {
            //   ("outttt", out);
            updateMutation.mutate(
                {
                    id: id,
                    data: prepareData(),
                },
                {
                    onSuccess: () => {
                        get_user ? navigate(-1) : navigate("/candidate/view");
                    },
                }
            );
        } else {
            createMutation.mutate({
                ...out,
                user: user_id,
            });
        }
        if (out.status === "Pending") {
            sendMailToAdmin(
                `Profile pending: ${combined_data.first_name} ${combined_data.last_name}`,
                "New profile changes",
                is_edit
                    ? "User made some changes in the profile. please review and approve the changes."
                    : "New profile has been created. please review the profile and approve/reject it."
            );
        }
    };

    // ("New Data", data);

    useEffect(() => {
        const timer = setTimeout(() => {
            setAutosave(true);
            const out = prepareData();
            const is_edit = !!id;
            if (is_edit) {
                // ("Auto updating....");
                updateMutation.mutate({
                    id: id,
                    data: out,
                });
            }
        }, AUTOSAVE_DURATION * 1000);

        return () => clearTimeout(timer);
    }, [data, id]);

    return (
        <Form style={{ paddingTop: "20px" }}>
            <Form.Group widths="equal">
                <Form.Field required>
                    <label>First Name</label>
                    <Input
                        name="first_name"
                        value={combined_data.first_name}
                        onChange={handleChange}
                    />
                </Form.Field>
                <Form.Field>
                    <label>Middle Name</label>
                    <Input
                        name="middle_name"
                        value={combined_data.middle_name}
                        onChange={handleChange}
                    />
                </Form.Field>
                <Form.Field required>
                    <label>Last Name</label>
                    <Input
                        name="last_name"
                        value={combined_data.last_name}
                        onChange={handleChange}
                    />
                </Form.Field>
            </Form.Group>
            <Form.Field required>
                <Form.Group inline>
                    <label>Gender</label>
                    <Form.Radio
                        label="Male"
                        value="Male"
                        checked={combined_data.gender === "Male"}
                        onChange={(e, { value }) =>
                            setData({ ...data, gender: value })
                        }
                    />
                    <Form.Radio
                        label="Female"
                        value="Female"
                        checked={combined_data.gender === "Female"}
                        onChange={(e, { value }) =>
                            setData({ ...data, gender: value })
                        }
                    />
                </Form.Group>
            </Form.Field>
            <Form.Group widths="equal">
                <Form.Field required>
                    <label>Email</label>
                    <Input
                        type="email"
                        name="email"
                        value={combined_data.email}
                        onChange={handleChange}
                    />
                </Form.Field>
            </Form.Group>
            <AddressPicker
                value={combined_data.addresses || []}
                setValue={(new_val) => setData({ ...data, addresses: new_val })}
            />
            <Form.Group widths="equal">
                <Form.Field>
                    <label>Petname</label>
                    <Input
                        name="petname"
                        value={combined_data.petname}
                        onChange={handleChange}
                    />
                </Form.Field>
                <Form.Field required>
                    <label>Maternal Surname</label>
                    <Input
                        name="maternal_surname"
                        value={combined_data.maternal_surname}
                        onChange={handleChange}
                    />
                </Form.Field>
                <Form.Field required>
                    <label>Native Place</label>
                    <Input
                        name="new_native_place"
                        value={combined_data.new_native_place}
                        onChange={handleChange}
                    />
                </Form.Field>
            </Form.Group>
            <Form.Group widths="equal">
                <Form.Field required>
                    <label>Main Photo</label>
                    <ImageField
                        value={combined_data.main_photo}
                        onChange={(new_val) =>
                            setData({ ...data, main_photo: new_val })
                        }
                        aspect={1 / 1}
                    />
                </Form.Field>
                <Form.Field>
                    <label>Other Photos</label>
                    <ImageField
                        value={combined_data.photos || []}
                        onChange={(new_val) =>
                            setData({ ...data, photos: new_val })
                        }
                        many={true}
                        aspect={undefined}
                    />
                </Form.Field>
            </Form.Group>
            <VideoPicker
                value={combined_data.main_video}
                onChange={(new_val) =>
                    setData({ ...data, main_video: new_val })
                }
            />
            <Form.Group widths="equal">
                <Form.Field required>
                    <label>Marital Status</label>
                    <Dropdown
                        value={combined_data.marital_status}
                        onChange={(e, { value }) =>
                            setData({ ...data, marital_status: value })
                        }
                        selection
                        options={[
                            {
                                key: "Unmarried",
                                text: "Unmarried",
                                value: "Unmarried",
                            },
                            {
                                key: "Divorced",
                                text: "Divorced",
                                value: "Divorced",
                            },
                            {
                                key: "Widow",
                                text: "Widow",
                                value: "Widow",
                            },
                            {
                                key: "Widower",
                                text: "Widower",
                                value: "Widower",
                            },
                        ]}
                    />
                </Form.Field>
                <Form.Field required>
                    <label>Birth Place</label>
                    <Input
                        name="new_birth_place"
                        value={combined_data.new_birth_place}
                        onChange={handleChange}
                    />
                </Form.Field>
            </Form.Group>
            <Form.Group widths="equal">
                <Form.Field required>
                    <label>
                        Birth Date
                        <label
                            style={{
                                fontWeight: "100",
                                fontSize: "13px",
                                marginLeft: "3px",
                            }}
                        >
                            (To select birth date click on calender icon
                            <Icon
                                name="calendar outline"
                                style={{
                                    marginLeft: "2px",
                                }}
                            />
                            below)
                        </label>
                    </label>
                    <Input
                        type="date"
                        name="brithday"
                        value={combined_data.brithday}
                        onChange={handleChange}
                        onKeyDown={(e) => e.preventDefault()}
                        max="2005-10-01"
                        // max="<current_year_minus_18>-01-01"
                    />
                </Form.Field>
                <Form.Field>
                    <label>
                        Birth Time
                        <label
                            style={{
                                fontWeight: "100",
                                fontSize: "13px",
                                marginLeft: "3px",
                            }}
                        >
                            (To select birth Time click on time icon
                            <Icon
                                name="time outline"
                                style={{
                                    marginLeft: "2px",
                                }}
                            />
                            below)
                        </label>
                    </label>
                    <Input
                        type="time"
                        name="birth_time"
                        value={combined_data.birth_time}
                        onChange={handleChange}
                        onKeyDown={(e) => e.preventDefault()}
                    />
                </Form.Field>
            </Form.Group>

            <Form.Group widths="equal">
                <Form.Field required>
                    <label>Height</label>
                    <HeightField
                        value={height}
                        setValue={(newVal) => setHeight(newVal)}
                    />
                </Form.Field>
                <Form.Field required>
                    <label>Weight</label>
                    <Input
                        name="weight"
                        type="number"
                        step="any"
                        value={combined_data.weight}
                        onChange={handleChange}
                        label={{ basic: false, content: "kg" }}
                        labelPosition="right"
                    />
                </Form.Field>
            </Form.Group>
            <Form.Group widths="equal">
                <LookupDropdown
                    type="Complexion"
                    label="Complexion"
                    value={combined_data.complexion}
                    onChange={(newVal) =>
                        setData({ ...data, complexion: newVal })
                    }
                />
                <LookupDropdown
                    type="BodyBuild"
                    label="Body Build"
                    value={combined_data.body_build}
                    onChange={(newVal) =>
                        setData({ ...data, body_build: newVal })
                    }
                />
            </Form.Group>
            <Form.Group widths="equal">
                <LookupDropdown
                    type="Blood Group"
                    label="Blood Group"
                    value={combined_data.blood_group}
                    onChange={(newVal) =>
                        setData({ ...data, blood_group: newVal })
                    }
                />
                <LookupDropdown
                    type="Gotra"
                    label="Gotra"
                    value={combined_data.gotra}
                    onChange={(newVal) => setData({ ...data, gotra: newVal })}
                />
            </Form.Group>
            <Form.Field error={invalids.partner_expectations}>
                <label>
                    What are your expectations from your life partner?
                </label>
                <TextArea
                    name="partner_expectations"
                    value={combined_data.partner_expectations}
                    onChange={handleChange}
                    rows={4}
                />
                <p className="info">
                    Only first 100 words will be printed in the book.
                </p>
                {invalids.partner_expectations && (
                    <p className="error" style={{ marginTop: "-10px" }}>
                        Must have atleast 10 words.
                    </p>
                )}
            </Form.Field>
            <Form.Group widths="equal">
                <Form.Field>
                    <label>No. of Brothers Married</label>
                    <Input
                        type="number"
                        name="brothers_married"
                        value={combined_data.brothers_married}
                        onChange={handleChange}
                    />
                </Form.Field>
                <Form.Field>
                    <label>No. of Brothers Unmarried</label>
                    <Input
                        type="number"
                        name="brothers_unmarried"
                        value={combined_data.brothers_unmarried}
                        onChange={handleChange}
                    />
                </Form.Field>
                <Form.Field>
                    <label>No. of Sisters Married</label>
                    <Input
                        type="number"
                        name="sisters_married"
                        value={combined_data.sisters_married}
                        onChange={handleChange}
                    />
                </Form.Field>{" "}
                <Form.Field>
                    <label>No. of Sisters Unmarried</label>
                    <Input
                        type="number"
                        name="sisters_unmarried"
                        value={combined_data.sisters_unmarried}
                        onChange={handleChange}
                    />
                </Form.Field>
            </Form.Group>
            <Form.Group widths="equal">
                <LookupDropdown
                    type="Job Type"
                    label="Job Type"
                    value={combined_data.job_type}
                    onChange={(newVal) =>
                        setData({ ...data, job_type: newVal })
                    }
                />
                <Form.Field>
                    <label>Organization Name</label>
                    <Input
                        name="organization_name"
                        value={combined_data.organization_name}
                        onChange={handleChange}
                    />
                </Form.Field>
                <Form.Field>
                    <label>Occupation</label>
                    <Input
                        name="occupation"
                        value={combined_data.occupation}
                        onChange={handleChange}
                    />
                </Form.Field>
            </Form.Group>
            <Form.Group widths="equal">
                <Form.Field>
                    <label>Designation</label>
                    <Input
                        name="designation"
                        value={combined_data.designation}
                        onChange={handleChange}
                    />
                </Form.Field>
                <Form.Field required>
                    <label>Income (per annum)</label>
                    <Input
                        type="number"
                        name="income"
                        value={combined_data.income}
                        onChange={handleChange}
                        label={
                            <Dropdown
                                name="income_currency"
                                defaultValue="INR"
                                value={combined_data.income_currency}
                                onChange={(e, { value }) =>
                                    setData({ ...data, income_currency: value })
                                }
                                options={[
                                    { key: "INR", text: "INR", value: "INR" },
                                    { key: "USD", text: "USD", value: "USD" },
                                    { key: "GBP", text: "GBP", value: "GBP" },
                                    { key: "EUR", text: "EUR", value: "EUR" },
                                ]}
                            />
                        }
                        labelPosition="left"
                    />
                </Form.Field>
            </Form.Group>

            <Form.Group widths="equal">
                <Form.Field required>
                    <label>Work Location</label>
                    <Input
                        // type="Location"
                        // label="Work Location"
                        value={combined_data.new_location}
                        onChange={(newVal) =>
                            setData({
                                ...data,
                                new_location: newVal.target.value,
                            })
                        }
                    />
                </Form.Field>
                <Form.Field required>
                    <label>Work Country</label>
                    <Input
                        // type="Location"
                        // label="Work Location"
                        value={combined_data.new_country}
                        onChange={(newVal) =>
                            setData({
                                ...data,
                                new_country: newVal.target.value,
                            })
                        }
                    />
                </Form.Field>
                {/* <LookupDropdown
          type="Country"
          label="Country"
          value={combined_data.country}
          onChange={(newVal) => setData({ ...data, country: newVal })}
        /> */}
            </Form.Group>

            <Form.Field inline>
                <Checkbox
                    label="Differently Abled"
                    onChange={(e, value) => {
                        // setData({ ...data, handicapped: value.checked });
                        if (value.checked) {
                            setDialog({ ...dialog, open: true });
                        } else {
                            setData({ ...data, is_handicapped: value.checked });
                        }
                    }}
                    checked={combined_data.is_handicapped}
                />
                {combined_data.is_handicapped ? (
                    <>
                        <label>(Mention disabality)</label>
                        <input
                            value={combined_data.mention_disability}
                            onChange={(e) => {
                                setData({
                                    ...data,
                                    mention_disability: e.target.value,
                                });
                            }}
                            style={{
                                marginLeft: "20px",
                            }}
                        />
                    </>
                ) : (
                    ""
                )}
            </Form.Field>
            <Form.Field>
                <Checkbox
                    label="Would you like to match horoscope?"
                    onChange={(e, value) => {
                        setData({ ...data, match_horoscope: value.checked });
                    }}
                    checked={combined_data.match_horoscope}
                />
            </Form.Field>
            <Form.Group widths="equal">
                {/* <Form.Field>
                    <label>Education Name</label>
                    <Input
                        name="education"
                        value={combined_data.education}
                        onChange={handleChange}
                    />
                </Form.Field> */}
                <LookupDropdown
                    type="Education Name"
                    label="Education"
                    value={combined_data.education}
                    onChange={(newVal) =>
                        setData({ ...data, education: newVal })
                    }
                />
                <Form.Field>
                    <label>Specialization</label>
                    <Input
                        name="education_stream"
                        value={combined_data.education_stream}
                        onChange={handleChange}
                        placeholder="Add any other degree / qualifications"
                    />
                    <p className="info">
                        Ex: Computer Science etc{" "}
                        <i>(any other degree/qualifications)</i>
                    </p>
                </Form.Field>
            </Form.Group>
            <Form.Group widths="equal">
                <Form.Field>
                    <label>Facebook Link</label>
                    <Input
                        name="social_facebok"
                        value={combined_data.social_facebook}
                        onChange={(e) =>
                            setData({
                                ...data,
                                social_facebook: e.target.value,
                            })
                        }
                        icon
                        placeholder="https://"
                    >
                        <input />
                        <Icon name="facebook" />
                    </Input>
                </Form.Field>
                <Form.Field>
                    <label>Linkedin Link</label>
                    <Input
                        name="social_linkedin"
                        value={combined_data.social_linkedin}
                        onChange={(e) =>
                            setData({
                                ...data,
                                social_linkedin: e.target.value,
                            })
                        }
                        icon
                        placeholder="https://"
                    >
                        <input />
                        <Icon name="linkedin" />
                    </Input>
                </Form.Field>
                <Form.Field>
                    <label>Twitter Link</label>
                    <Input
                        name="social_twitter"
                        value={combined_data.social_twitter}
                        onChange={(e) =>
                            setData({
                                ...data,
                                social_twitter: e.target.value,
                            })
                        }
                        placeholder="https://"
                        icon
                    >
                        <input />
                        <Icon name="twitter" />
                    </Input>
                </Form.Field>
            </Form.Group>

            <Form.Field>
                <div
                    style={{
                        display: "flex",
                        gap: "7px",
                    }}
                >
                    <Button
                        onClick={submitForm}
                        icon
                        positive
                        labelPosition="right"
                        loading={
                            createMutation.isLoading || updateMutation.isLoading
                        }
                    >
                        {edit ? "Submit for Verification" : "Submit"}
                        <Icon name="arrow right" />
                    </Button>
                    <Button
                        onClick={() => submitForm("Draft")}
                        icon
                        labelPosition="right"
                        primary
                        loading={
                            createMutation.isLoading || updateMutation.isLoading
                        }
                    >
                        Save in Draft
                        <Icon name="arrow right" />
                    </Button>
                </div>
            </Form.Field>
            <Modal open={dialog.open} size="tiny">
                <Modal.Content>
                    <p>{dialog.msg}</p>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        color="black"
                        onClick={() => {
                            setDialog({ ...dialog, open: false });
                            setData({ ...data, is_handicapped: false });
                        }}
                    >
                        No
                    </Button>
                    <Button
                        primary
                        onClick={() => {
                            setDialog({ ...dialog, open: false });
                            setData({ ...data, is_handicapped: true });
                        }}
                    >
                        Yes
                    </Button>
                </Modal.Actions>
            </Modal>
        </Form>
    );
}

export default CandidateForm;
