import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import relativeTime from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";
import customParseFormat from "dayjs/plugin/customParseFormat";
import calendar from "dayjs/plugin/calendar";

dayjs.extend(utc);
// dayjs.extend(timezone);
// dayjs.tz.setDefault("Asia/Kolkata");
dayjs.extend(relativeTime);
dayjs.extend(customParseFormat);
dayjs.extend(calendar);

// format= r:relative, c:calender, d:datetime
export const getTime = (dt, format = "r") => {
    const ndt = dayjs(dt).utcOffset(330, true).local();
    switch (format) {
        case "r":
            return ndt.fromNow();
        case "d":
            return ndt.format("hh:mm A DD/MM/YYYY");
        case "od":
            return ndt.format("DD/MM/YYYY");
        case "c":
            return dayjs().calendar(ndt);
        default:
            return ndt.format("hh:mm A DD/MM/YYYY");
    }

    // return (
    //     dayjs(dt)
    // .add(330, "m")
    //         .utcOffset(0, true)
    //         .local()
    // .utc()
    // .fromNow()
    //         .format("hh:mm A DD/MM/YYYY")
};

export function formatTime(tm) {
    if (!tm) {
        return "-";
    }
    return tm?.toString()?.substring(0, 5);
}

export function convertToAMPM(timeString) {
    if (!timeString) {
        return "-";
    }
    // Split the time string into hours and minutes
    const [hours, minutes] = timeString.split(":");

    // Convert hours to a number
    let hoursNum = parseInt(hours, 10);

    // Determine AM or PM
    const period = hoursNum >= 12 ? "PM" : "AM";

    // Convert to 12-hour format
    hoursNum = hoursNum % 12 || 12;

    // Pad single-digit hours with a leading zero
    const formattedHours = hoursNum < 10 ? `0${hoursNum}` : hoursNum;

    // Construct the new time string
    const formattedTime = `${formattedHours}:${minutes} ${period}`;

    return formattedTime;
}

// Formatting
// https://day.js.org/docs/en/display/format
