import React, { useEffect, useMemo } from "react";
import Screen from "../commons/Screen";
import { Button, Icon } from "semantic-ui-react";
import NoCandidate from "./NoCandidate";
import { Link } from "react-router-dom";
import { useItemsFilter } from "../_actions/items";
import CandidateDetailView from "./CandidateDetailView";
import MessageBox from "../commons/MessageBox";
import { useCurrentRole } from "../_actions/roles";
import AccessDenied from "../commons/AccessDenied";
import { IS_DEV } from "../constants";

const PROFILE_FIELDS =
    "fields=*,native_place.name,birth_place.name,complexion.name,body_build.name,blood_group.name,location.name,country.name,job_type.name,addresses.*,photos.*,education.*,gotra.*,user_created.*,new_native_place,new_birth_place,new_location,user.first_name,user.last_name,user.email";

function MyCandidate() {
    const { role_name } = useCurrentRole();
    const { isSuccess, isLoading, data, refetch } = useItemsFilter(
        "qd_matrimonial_profile",
        `?filter[user][_eq]=$CURRENT_USER&${PROFILE_FIELDS}`,
        role_name === "user"
    );

    //   const profile_data = useMemo(() => {
    //     console.log("Profile Data", data);
    //     return data?.[0];
    //   }, [isSuccess]);

    useEffect(() => {
        refetch();
    }, []);

    const profile_data = isSuccess && data[0];

    if (role_name !== "user") {
        return (
            <Screen>
                <AccessDenied />
            </Screen>
        );
    }

    return (
        <Screen
            title="Aspirant Profile"
            wrapper_styles={{
                paddingTop: "20px",
            }}
            actions={
                data?.length > 0 &&
                (IS_DEV || profile_data?.status !== "Pending")
                    ? [
                          <Link to="/candidate/edit">
                              <Button primary icon labelPosition="left">
                                  <Icon name="edit" />
                                  Edit Profile
                              </Button>
                          </Link>,
                      ]
                    : []
            }
        >
            {isSuccess && data?.length <= 0 && (
                <NoCandidate
                    text="No Candidate added"
                    action={
                        <Link to="/candidate/create">
                            <Button
                                icon
                                labelPosition="left"
                                style={{ marginTop: "-20px" }}
                            >
                                <Icon name="add" />
                                Add Candidate
                            </Button>
                        </Link>
                    }
                />
            )}
            {isSuccess && profile_data?.status === "Active" && (
                <MessageBox
                    type="success"
                    title="Your Profile is approved and will be printed in Suchi."
                />
            )}
            {isSuccess && profile_data?.status === "Rejected" && (
                <MessageBox
                    type="error"
                    title="Your profile has been rejected. Please fix the issues and submit again."
                    content={profile_data?.rejection_reason}
                />
            )}
            {isSuccess && profile_data?.status === "Pending" && (
                <MessageBox
                    type="info"
                    title="Your profile is under review. we'll inform you once it is approved."
                />
            )}
            {isSuccess && profile_data?.status === "Draft" && (
                <MessageBox
                    type="warn"
                    title="Your profile is incomplete and could't be submitted to review. please fix following issue to proceed."
                    content={Object.values(profile_data?.validation_errors).map(
                        (item) => "• " + item
                    )}
                    actions={[
                        <Link to="/candidate/edit">
                            <Button color="yellow" icon labelPosition="left">
                                <Icon name="edit" />
                                Edit Profile
                            </Button>
                        </Link>,
                    ]}
                />
            )}
            {isSuccess && profile_data && (
                <CandidateDetailView data={profile_data} />
            )}
        </Screen>
    );
}

export default MyCandidate;
