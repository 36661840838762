import React from "react";
import { Button, Icon } from "semantic-ui-react";
import { restoreAccessToken } from "../_helpers/auth";
import { BASE_URL } from "../constants";
import { CSVLink } from "react-csv";
import { useItemsFilter } from "../_actions/items";

const FIELDS =
    "fields=*,native_place.name,location.name,birth_place.name,blood_group.name,job_type.name,complexion.name,body_build.name,country.name,gotra.name,education.name,addresses.name,addresses.mobile,addresses.mobile_alternative,addresses.address,addresses.city,addresses.pincode,addresses.country.name";

function DownloadProfilesButton({ status }) {
    const { isLoading, isSuccess, data } = useItemsFilter(
        "qd_matrimonial_profile",
        `?${FIELDS}&limit=-1&${status ? `&filter[status][_eq]=${status}` : ``}`,
        true
    );

    const parseData = (data) => {
        const out = data.map((item) => {
            const addr = item.addresses?.[0] || {};
            const row = {};
            Object.keys(item).map((key) => {
                if (typeof item[key] !== "object") {
                    row[key] = item[key];
                }
            });
            const out_row = {
                first_name: item.first_name,
                last_name: item.last_name,
                ...row,
                job_type: item.job_type?.name,
                gotra: item.gotra?.name,
                complexion: item.complexion?.name,
                body_build: item.body_build?.name,
                blood_group: item.blood_group?.name,
                education: item.education?.name,
                country: item.country?.name,
                address_name: addr.name,
                address_city: addr.city,
                address_address: addr.address,
                address_mobile: addr.mobile,
                address_mobile_alternate: addr.mobile_alternative,
                address_country: addr.country?.name,
            };
            removeCommasFromObject(out_row);
            return out_row;
        });
        console.log("OUT", out);
        return out;
    };

    if (isSuccess) {
        console.log("DAta to download", data);
        return (
            <CSVLink
                data={parseData(data)}
                filename={"matrimonial_profiles.csv"}
            >
                <Button icon labelPosition="left" primary>
                    <Icon name="download" /> Download
                </Button>
            </CSVLink>
        );
    }
    return <></>;
}

export default DownloadProfilesButton;

function removeCommasFromObject(obj) {
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            if (typeof obj[key] === "string") {
                // Remove commas from string values
                obj[key] = obj[key].replace(/,/g, "").replace(/\n/g, "");
            } else if (typeof obj[key] === "object") {
                // Recursively call the function for nested objects
                removeCommasFromObject(obj[key]);
            }
        }
    }
}
