import React, { useMemo } from "react";
import Screen from "../commons/Screen";
import { Button, Icon, Dropdown } from "semantic-ui-react";
import { Link, Outlet, useLocation } from "react-router-dom";
import { BASE_URL } from "../constants";
import { restoreAccessToken } from "../_helpers/auth";

function OrderManagement() {
    const location = useLocation();

    const filter = useMemo(() => {
        if (location.pathname === "/admin/orders/ordered") {
            return "filter[order_status][_eq]=ordered";
        } else if (location.pathname === "/admin/orders/dispatched") {
            return "filter[order_status][_eq]=dispatched";
        } else if (location.pathname === "/admin/orders/delivered") {
            return "filter[order_status][_eq]=delivered";
        }
    }, [location]);

    return (
        <Screen
            title="Suchi Book Orders"
            wrapper_styles={{
                marginTop: "-30px",
            }}
            actions={[
                <Button.Group>
                    <Link to="/admin/orders/ordered">
                        <Button
                            primary={
                                location?.pathname === "/admin/orders/ordered"
                            }
                        >
                            Ordered
                        </Button>
                    </Link>
                    <Link to="/admin/orders/dispatched">
                        <Button
                            primary={
                                location?.pathname ===
                                "/admin/orders/dispatched"
                            }
                        >
                            Dispatched
                        </Button>
                    </Link>
                    <Link to="/admin/orders/delivered">
                        <Button
                            primary={
                                location?.pathname === "/admin/orders/delivered"
                            }
                        >
                            Delivered
                        </Button>
                    </Link>
                </Button.Group>,
                <a
                    href={`${BASE_URL}items/qd_order?${filter}&filter[status][_eq]=paid&sort=-date_updated&export=csv&limit=-1&access_token=${restoreAccessToken()}`}
                    target="_blank"
                    rel="noreferrer"
                >
                    <Button icon labelPosition="left" primary>
                        <Icon name="download" /> Download
                    </Button>
                </a>,
            ]}
        >
            <Outlet />
        </Screen>
    );
}

export default OrderManagement;
